* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter.ttf") format("truetype");
}

:root {
  /* font families */
  --inter: Inter, sans-serif;

  /* colors */
  --yellow: #edbe00;
  --dark-yellow: #e0b300;
  --darker-yellow: #cda400;
  --light-yellow: #ffd700;
  --white: #ffffff;
  --black: #000000;
  --dull-yellow: #cda400;
  --darkest-yellow: #be9800;
  --dark-gray: #666666;
  --light-gray: #969696;
  --hover-gray: #f9f9f9;
  --active-gray: #efefef;
  --red: #c9311a

  /* font sizes */
  --h1: 44px;
  --h2: clamp(1.625rem, 1.5179rem + 0.5357vw, 2rem); /* 26px - 32px */
  --h3: clamp(1.375rem, 1.2679rem + 0.5357vw, 1.75rem); /* 22px - 28px */
  --h4: clamp(1.25rem, 1.1786rem + 0.3571vw, 1.5rem); /* 20px - 24px */
  --h5: clamp(1.125rem, 1.125rem + 0vw, 1.125rem); /* 18px - 18px */
  --h6: clamp(1rem, 1rem + 0vw, 1rem); /* 16px - 16px */
  --cta: clamp(1rem, 1rem + 0vw, 1rem); /* 16px - 16px */
  --xl: clamp(1.125rem, 1.125rem + 0vw, 1.125rem); /* 18px - 18px */
  --lg: clamp(1rem, 1rem + 0vw, 1rem); /* 16px - 16px */
  --md: clamp(0.875rem, 0.875rem + 0vw, 0.875rem); /* 14px - 14px */
  --sm: clamp(0.875rem, 0.875rem + 0vw, 0.875rem); /* 14px - 14px */
  --xs: clamp(0.75rem, 0.75rem + 0vw, 0.75rem); /* 12px - 12px */
}

select {
  -webkit-appearance: none;
}
input {
  font-family: var(--inter);
}

/* Custom ScrollBar */
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgb(204, 188, 188);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--yellow);
  border-radius: 5px;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.page-link {
  text-decoration: none;
  color: var(--light-gray);
  cursor: pointer;
}
.active .page-link {
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--yellow);
  background-color: var(--yellow);
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Skeleton Styles */
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #000;
  opacity: 0.3;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.custom-textLine {
  text-decoration: underline;
  text-decoration-thickness: 1.1px;
  text-underline-offset: 2px;
}

.toastBody {
  font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.custom-select-wrapper {
  position: relative;
}

.custom-select-wrapper .select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-select-wrapper .custom-select {
  padding-right: 30px;
}
